import { Wrapper, Body, SectionTitle, BorderImage } from "./Styles";

function LegendaryItems() {
	return (
		<Wrapper>
			<BorderImage src="https://res.cloudinary.com/wy/image/upload/w_1800,f_auto,dpr_auto/v1638993005/loot-larp/Copie_de__DSC5531.jpg" />
			<SectionTitle id="legendary-items">Legendary Items</SectionTitle>
			<Body>
				We will also be forging eight exclusive Legendary items to be auctioned as Redemption NFTs to the highest
				bidders. These will be one-of-a-kind customizable pieces, to be announced and auctioned daily after our public
				mint is complete.
				<br />
				<br />
				Beyond the prestige and stunning visual flex, our Legendary items will also enable special moments and easter
				eggs in all of our games and social experiences.
			</Body>
		</Wrapper>
	);
}

export default LegendaryItems;
