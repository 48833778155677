import styled from "styled-components";
import { bp } from "./Breakpoints";
import { Wrapper, SectionTitle } from "./Styles";

function Roadmap() {
	return (
		<Wrapper id="roadmap">
			<SectionTitle>Roadmap</SectionTitle>
			<Inner>
				{/* <Date>
					2nd Week
					<br />
					of December
				</Date> */}
				<Item>Private Mint Signup Opens</Item>
			</Inner>
			<Inner>
				{/* <Date>
					3rd Week
					<br />
					of December
				</Date> */}
				<Item>
					<Line />
					Private Mint
				</Item>
			</Inner>
			<Inner>
				{/* <Date>
					3rd Week
					<br />
					of December
				</Date> */}
				<Item>
					<Line />
					Public Mint
				</Item>
			</Inner>
			<Inner>
				{/* <Date>
					4th Week
					<br />
					of December
				</Date> */}
				<Item>
					<Line />
					Legendary Item Auctions
				</Item>
			</Inner>
			<Inner>
				{/* <Date>
					Last Week
					<br />
					of December
				</Date> */}
				<Item>
					<Line />
					Loot Pairing Snapshot, Item Forge Opens
				</Item>
			</Inner>
			<Inner>
				{/* <Date>Q1 2022</Date> */}
				<Item>
					<Line />
					<Logo>
						<img
							alt="loot larp logo"
							src="https://res.cloudinary.com/wy/image/upload/v1637895272/loot-larp/LootLARP_logo.svg"
						></img>
					</Logo>{" "}
					@ NFT.NYC
				</Item>
			</Inner>
		</Wrapper>
	);
}

export default Roadmap;

const Inner = styled.div`
	display: flex;
	text-align: center;
	margin: 12px auto;
	justify-content: center;
`;
const Date = styled.div`
	color: #52ff00;
	font-size: 15px;
	min-width: 100px;
	padding-top: 6px; // aligns date to top of items

	@media (min-width: ${bp.sm}) {
		font-size: 25px;
		min-width: 200px;
	}
`;
const Item = styled.div`
	text-align: center;
`;

const Logo = styled.span`
	img {
		margin: 0;
		padding: 0;
		margin-right: 6px;
		width: 120px;
		height: auto;
		transform: translateY(6px);
	}

	@media (min-width: ${bp.sm}) {
		img {
			width: 165px;
			transform: translateY(12px);
		}
	}
`;

const Line = styled.div`
	width: 5px;
	height: 50px;
	border-left: 5px dotted #52ff00;
	margin: 0 auto 12px;

	@media (min-width: ${bp.sm}) {
		height: 75px;
	}
`;
