import React, { Component } from "react";
import styled from "styled-components";
import { Wrapper, Panel, SectionTitle } from "./Styles";
export default class FAQ extends Component {
	componentDidMount() {
		var acc = document.getElementsByClassName("accordion");
		var i;

		for (i = 0; i < acc.length; i++) {
			acc[i].addEventListener("click", function () {
				this.classList.toggle("active");
				var panel = this.nextElementSibling;
				if (panel.style.display === "block") {
					panel.style.display = "none";
				} else {
					panel.style.display = "block";
				}
			});
		}
	}
	render() {
		return (
			<Wrapper id="faq">
				<SectionTitle>FAQ</SectionTitle>
				<button className="accordion" id="first">
					What is Loot ?
				</button>
				<Panel>
					<p>
						You can{" "}
						<a
							href="https://lootofloot.substack.com/p/loot-a-brief-guide-of-the-bottom"
							target="_blank"
							rel="noreferrer"
						>
							read more here about dhof's Loot NFT
						</a>{" "}
						and how it has captured the imagination of web3 with its permissionless &amp; composable approach to
						bottoms-up storytelling.
					</p>
				</Panel>
				<button className="accordion">What is a SiLo chip and how does it work?</button>
				<Panel>
					<p>
						<a href="https://kong.land/" target="_blank" rel="noreferrer">
							KONG Land
						</a>{" "}
						DAO SiLo (Silicon Locked Contracts) chips are low-cost, durable, secure elements that can be
						cryptographically linked to an <em>Ethereum</em> smart contract. <br />
						<br />
						<Logo>
							<img
								alt="loot larp logo"
								src="https://res.cloudinary.com/wy/image/upload/v1637895272/loot-larp/LootLARP_logo.svg"
							></img>
						</Logo>{" "}
						gear is exclusively &amp; proudly <em>Made In KONG Land.</em>
						<br />
						<br />
						SiLos are the size of a coin and can be embedded, attached, or adhered to any object.
					</p>
					<Silo>
						<img alt="silo chip" src="https://res.cloudinary.com/wy/image/upload/v1638583825/loot-larp/silo_chip.jpg" />
					</Silo>
					<p>
						Each chip self-generates a key-pair, the public portion of which is added to a registry contract. Embedding
						a SiLo into a physical item transforms it into a crypto-asset that can be verified on chain, as well as
						interacted with IRL.
						<br />
						<br />
						<img
							alt="silo chip"
							src="https://res.cloudinary.com/wy/image/upload/v1638420948/loot-larp/lootlarp_vectors_01.svg"
						/>
						<br />
						<br />
						Anyone can scan the SiLo via NFC on their smartphone to verify the private key and unlock access to the
						tokenized assets stored in the smart contract, prove authenticity, or even trigger real-world actions.
						<br />
						<br />
						<img
							alt="silo chip"
							src="https://res.cloudinary.com/wy/image/upload/v1638420816/loot-larp/lootlarp_vectors_02.svg"
						/>
					</p>
				</Panel>

				<button className="accordion">What can attendees look forward to at the LARP event?</button>
				<Panel>
					<p>
						For one night only, we will host a gauntlet of quests &amp; games, created by our community of builders.{" "}
						<br />
						<br />
						In-game achievements are immediately recorded to the blockchain, triggering a variety of real-world unlocks,
						driving dramatic narrative moments for all players to enjoy collectively within an interactive real-life
						environment.
						<br />
						<br />
						This event will gather an elite assembly of builders, thought-leaders, community champions and metaverse
						explorers— those at the cutting edge of shaping web3.
					</p>
				</Panel>

				<button className="accordion">What are our future plans after the event at NFT.NYC?</button>
				<Panel>
					<p>
						Those in attendance are heeding a call to join our community dedicated to exploring and standardizing
						tokenized <Script>XRL (Extended Real Life)</Script> experiences, in service of an open and interoperable
						metaverse.
						<br />
						<br />
						Using this event as a blueprint, we will roll out progressively richer interactive stories, set in unique
						locations that resonate with the spirit of our community.
					</p>
				</Panel>

				<button className="accordion">How does Redemption work?</button>
				<Panel>
					<p>
						All Redemption NFTs will serve as tickets to an in-person event that will take place off-site at NFT.NYC in
						2022. All items will only be claimable in person by their owners at this event—{" "}
						<mark>you must arrive within the redemption window</mark> to ensure that you receive your physical NFT.
					</p>
				</Panel>

				<button className="accordion">How are items paired?</button>
				<Panel>
					<p>
						After all of the NFTs are minted, there will be a pairing process to connect existing <em>Loot</em> or{" "}
						<em>More Loot </em>
						NFTs to the physical items being created. This will be done by taking periodic snapshots of Redemption NFT
						holders' wallet addresses to check for <em>Loot/mLoot</em> NFTs present in their wallets. Redemption NFTs
						will be paired with all <em>Loot/mLoot</em> NFTs in the same wallet.
						<br /> <br />
						Pairing must be done by a "drop dead" date (TBA) for our production, otherwise the Redemption NFT will be
						marked as "unpaired", and mint supply will be increased to ensure scalpers don't prevent engaged
						participants from joining us at the event. No extra benefits will come to unpaired Redemption NFT holders—
						there will be no option to claim an item or attend our event.
					</p>
				</Panel>

				<button className="accordion">What counts as pairing failure?</button>
				<Panel>
					<p>
						If there is no Loot or More Loot NFT held in the wallet containing the Redemption NFT, the Redemption NFT
						will count as "unpaired". There needs to be a unique Loot or More Loot for each Redemption NFT in a wallet -
						multiple items cannot be paired to the same Loot or More Loot NFT.
					</p>
				</Panel>

				<button className="accordion">What if I can't make it to the event?</button>
				<Panel>
					<p>
						We understand that circumstances change, especially with travel. However, to ensure the best experience
						possible for participants at our event, we will be unlocking an additional supply of physical NFTs in
						accordance with the number of unclaimed physical NFTs at the end of the redemption window. This helps to
						ensure that the event is full. Those who cannot attend will have their Redemption NFTs converted to "Rugged"
						NFTs— digi-physical SiLo-enabled Magic Rugs. These will be available for shipping at a later date. We intend
						to create special ways for holders of "Rugged" NFTs to participate in future drops and events.
					</p>
				</Panel>
				<button className="accordion">
					What happens if unforeseen circumstances (e.g. COVID lockdowns) prevent the event from happening?
				</button>
				<Panel>
					<p>
						Our fingers are crossed in hopes that this won't happen 🤞 In the event that it does, we will postpone and
						relocate our event to another storybook venue, coinciding with another community conference (ie. Devcon, ETH
						Denver, etc). In this event, no NFTs will be converted into "Rugged" NFTs.
					</p>
				</Panel>

				<button className="accordion">What are the supply allocations for the NFTs?</button>
				<Panel>
					<table id="customers">
						<tbody>
							<tr>
								<th> </th>
								<th>Standard</th>
								<th>Legendary</th>
							</tr>
							<tr>
								<td>Supply</td>
								<td>500</td>
								<td>8</td>
							</tr>
							<tr>
								<td>Price</td>
								<td>0.5 ETH</td>
								<td>Auction</td>
							</tr>
							<tr>
								<td>Mint limit per wallet</td>
								<td>2</td>
								<td>2</td>
							</tr>
						</tbody>
					</table>
					<p>A portion of the mint supply will be allocated to team members and community contributors.</p>
				</Panel>
			</Wrapper>
		);
	}
}

const Logo = styled.span`
	img {
		margin: 0;
		padding: 0;
		margin-right: 3px;
		width: 90px;
		height: auto;
		transform: translateY(6px);
	}
`;

const Silo = styled.div`
	img {
		width: 50%;
		height: auto;
		margin: auto;
		display: flex;
	}
`;

const Script = styled.span`
	font-family: "Fondamento";
`;
