import React, { useState } from "react";
import styled from "styled-components";
import GlobalStyle from "./GlobalStyles";
import { bp } from "./Breakpoints";
import { HashLink as Link } from "react-router-hash-link";
import { injected } from "./wallet/connectors";
import { useWeb3React } from "@web3-react/core";

function Navigation() {
	const [toggled, toggleMenu] = useState(false);

	async function connect() {
		try {
			await activate(injected);
		} catch (ex) {
			console.log(ex);
		}
	}

	async function disconnect() {
		try {
			await deactivate();
		} catch (ex) {
			console.log(ex);
		}
	}

	// render() {
	const { active, account, activate, deactivate } = useWeb3React();

	return (
		<Wrapper>
			<GlobalStyle />
			<Header>
				<Logo>
					<Link to={{ pathname: "/" }}>
						<img
							alt="loot larp logo"
							src="https://res.cloudinary.com/wy/image/upload/v1637895272/loot-larp/LootLARP_logo.svg"
						></img>
					</Link>
				</Logo>

				<Menu onMouseEnter={() => toggleMenu(true)}>
					<Hamburger onClick={() => toggleMenu(!toggled)}>
						<Line />
						<Line />
						<Line />
					</Hamburger>
					{toggled === true ? (
						<SideNav onMouseLeave={() => toggleMenu(false)}>
							<div onClick={() => toggleMenu(false)}>
								<Link to={{ pathname: "/", hash: "#" }}>Mint</Link>
							</div>
							<div onClick={() => toggleMenu(false)}>
								{" "}
								<Link to={{ pathname: "/", hash: "#wtf" }}>WTF Larp?</Link>
							</div>
							<div onClick={() => toggleMenu(false)}>
								<Link to={{ pathname: "/", hash: "#legendary-items" }}>Legendary Items</Link>
							</div>
							<div onClick={() => toggleMenu(false)}>
								<Link to={{ pathname: "/", hash: "#roadmap" }}>Roadmap</Link>
							</div>
							<div onClick={() => toggleMenu(false)}>
								<Link to={{ pathname: "/", hash: "#faq" }}>FAQ</Link>
							</div>
							<div onClick={() => toggleMenu(false)}>
								<Link to={{ pathname: "/", hash: "#participate" }}>How to Participate</Link>
							</div>
							<div onClick={() => toggleMenu(false)}>
								<Link to="item-forge">Item Forge</Link>
							</div>
							<MobileOnly>
								<br />
								<div onClick={() => toggleMenu(false)}>
									<a href="https://discord.gg/dxFhYe7RYg" target="_blank" rel="noreferrer">
										Discord
									</a>
								</div>

								<div onClick={() => toggleMenu(false)}>
									<a href="https://t.me/+OmvtahcDY4gyMjVh" target="_blank" rel="noreferrer">
										Telegram
									</a>
								</div>

								<div onClick={() => toggleMenu(false)}>
									<a href="https://www.twitter.com/LootLARP" target="_blank" rel="noreferrer">
										Twitter
									</a>
								</div>
							</MobileOnly>
						</SideNav>
					) : null}
				</Menu>

				<Socials>
					<a href="https://www.twitter.com/LootLARP" target="_blank" rel="noreferrer">
						<Icon
							invert
							alt="twitter logo"
							src="https://res.cloudinary.com/wy/image/upload/v1638504925/loot-larp/iconmonstr-twitter-4.svg"
						/>
					</a>
					<a href="https://t.me/+OmvtahcDY4gyMjVh" target="_blank" rel="noreferrer">
						<Icon
							invert
							alt="telegram logo"
							src="https://res.cloudinary.com/wy/image/upload/v1638505490/loot-larp/telegram_logo.svg"
						/>
					</a>
					<a href="https://discord.gg/dxFhYe7RYg" target="_blank" rel="noreferrer">
						<Icon
							invert
							alt="discord logo"
							src="https://res.cloudinary.com/wy/image/upload/v1638505490/loot-larp/discord_logo.svg"
						/>
					</a>
					{active ? (
						<Wallet onClick={disconnect}>Disconnect Wallet</Wallet>
					) : (
						<Wallet onClick={connect}>Connect Wallet</Wallet>
					)}
				</Socials>
				<Connection>{active ? <span>Connected with {account}</span> : <span></span>}</Connection>
			</Header>
		</Wrapper>
	);
}
// }

export default Navigation;

const Wrapper = styled.div``;

const Logo = styled.div`
	justify-content: center;
	margin: auto;
	align-items: center;
	display: flex;
	top: 12px;
	left: 0;
	right: 0;
	position: fixed;
	z-index: 100;
	width: fit-content;
	img {
		width: 150px;
		height: auto;
	}
`;

const Socials = styled.div`
	right: 0;
	left: 0;
	display: flex;
	margin: auto;
	justify-content: center;

	@media (min-width: ${bp.md}) {
		position: fixed;
		top: 12px;
		right: 12px;
		left: initial;
	}
`;

const Icon = styled.img`
	width: 24px;
	height: auto;
	filter: ${(props) => (props.invert ? "invert(1)" : "invert(0)")};
	margin-right: 6px;
	display: none;
	margin-top: 8px;

	&:hover {
		opacity: 0.5;
		cursor: pointer;
	}
	@media (min-width: ${bp.lg}) {
		width: 32px;
		margin-top: 3px;
	}
	@media (min-width: ${bp.md}) {
		display: initial;
	}
`;

const Menu = styled.div`
	position: fixed;
	top: 12px;
	left: 12px;
	padding-right: 100px;
`;

const SideNav = styled.div`
	font-family: "MW Regular";
	position: fixed;
	top: 48px;
	scroll-behavior: smooth;
	background: #030304;
	padding: 12px;
	left: 0;
	line-height: 1.2;
	width: 100vw;
	font-size: 30px;
	height: 100vh;

	@media (min-width: ${bp.sm}) {
		font-size: 25px;
		width: fit-content;
		height: 100vh;
		padding-right: 48px;
	}

	div {
		text-decoration: underline;

		&:hover {
			text-decoration: none;
			cursor: pointer;
		}

		@media (hover: none) {
			a {
				&:hover {
					text-decoration: none;
				}
			}
		}
	}
`;

const Wallet = styled.div`
	font-size: 25px;
	margin-left: 6px;
	border: 2px solid;
	padding: 4px;
	height: 36px;
	display: none;
	cursor: pointer;

	a {
		text-decoration: none;
	}

	&:hover {
		background: white;
		color: #030304;
		border: 2px solid white;

		a {
			color: #030304;
		}
	}

	@media (min-width: ${bp.md}) {
		display: initial;
	}
`;

const Header = styled.div`
	position: fixed;
	background: #030304;
	width: 100vw;
	height: 65px;
	top: 0;
	left: 0;
	z-index: 5;
`;

const Hamburger = styled.div`
	position: fixed;
	left: 12px;
	top: 24px;
	z-index: 10;
	cursor: pointer;
`;

const Line = styled.div`
	width: 24px;
	height: 2px;
	margin-bottom: 4px;
	color: white;
	background: white;
	cursor: pointer; ;
`;

const MobileOnly = styled.div`
	display: initial;
	@media (min-width: ${bp.sm}) {
		display: none;
	}
`;
const Connection = styled.div`
	font-size: 10px;
	position: absolute;
	right: 12px;
	top: 60px;
	display: none;

	@media (min-width: ${bp.md}) {
		display: initial;
	}
`;
