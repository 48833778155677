export const bpR = {
	// raw screen widths
	xs: 375,
	sm: 576,
	md: 768,
	lg: 992,
	xl: 1200,
	xxl: 1920, // MUI value
	xxxl: 2200,
};
export const bp = {
	// screen widths in pixels
	xs: `${bpR.xs}px`,
	sm: `${bpR.sm}px`,
	md: `${bpR.md}px`,
	lg: `${bpR.lg}px`,
	xl: `${bpR.xl}px`,
	xxl: `${bpR.xxl}px`,
	xxxl: `${bpR.xxxl}px`,
};
