import React from "react";
import "./App.css";
import styled from "styled-components";
import GlobalStyle from "./components/GlobalStyles";
import { bp } from "./components/Breakpoints";
import Intro from "./components/Intro";
import FAQ from "./components/FAQ";
import WTF from "./components/WTF";
import Roadmap from "./components/Roadmap";
import LegendaryItems from "./components/LegendaryItems";
import Navigation from "./components/Navigation";
import Participate from "./components/Participate";
import { AppWrapper } from "./components/Styles";
import { Web3ReactProvider } from "@web3-react/core";
import Web3 from "web3";
import { Helmet } from "react-helmet";

function getLibrary(provider) {
	return new Web3(provider);
}

function App() {
	return (
		<Web3ReactProvider getLibrary={getLibrary}>
			<AppWrapper>
				<GlobalStyle />
				{<Navigation />}
				<InnerWrapper>
					<Introduction>
						<video
							muted
							loop
							autoPlay
							playsInline
							poster="https://res.cloudinary.com/wy/image/upload/v1638767253/loot-larp/poster.jpg"
							src="https://res.cloudinary.com/wy/video/upload/v1639188308/loot-larp/2021-12-09-Redemption-2k-compressed.mp4"
						></video>
						<Intro />
					</Introduction>

					<section className="parallax">
						<div className="parallax-inner">
							<Title>Adorn thineselves with enchanted NFT gear, for adventure awaits!</Title>
						</div>
					</section>
					<WTF />
					<section className="parallax-1">
						<div className="parallax-inner"></div>
					</section>
					<LegendaryItems />
					<Roadmap />
					<FAQ />
					<section className="parallax-2">
						<div className="parallax-inner"></div>
					</section>
					<Participate />
				</InnerWrapper>
			</AppWrapper>
		</Web3ReactProvider>
	);
}

export default App;

const InnerWrapper = styled.div`
	.parallax {
		@media (min-width: ${bp.sm}) {
			background: url("https://res.cloudinary.com/wy/image/upload/w_2500,f_auto,dpr_auto/v1638993009/loot-larp/Copie_de__DSC6035.jpg")
				fixed no-repeat;
			background-position: center;
		}
	}

	.parallax-1 {
		@media (min-width: ${bp.sm}) {
			background: url("https://res.cloudinary.com/wy/image/upload/w_2500,f_auto,dpr_auto/v1638993005/loot-larp/Copie_de__DSC5531.jpg")
				no-repeat fixed;
			background-position: center;
		}
	}

	.parallax-2 {
		@media (min-width: ${bp.sm}) {
			background: url("https://res.cloudinary.com/wy/image/upload/w_2500,f_auto,dpr_auto/v1638993015/loot-larp/Copie_de_IMG_3957.jpg")
				no-repeat fixed 10%;
			background-position: center 20%;
		}
	}

	.parallax,
	.parallax-1,
	.parallax-2 {
		display: none;

		@media (min-width: ${bp.sm}) {
			border: 10px solid #52ff00;
			width: calc(80vw - 24px);
			background-size: cover;
			display: block;
			margin: auto;
			margin-bottom: 48px;
			background-attachment: fixed !important;
			max-width: 2000px;
		}
	}

	.parallax-inner {
		display: none;
		@media (min-width: ${bp.sm}) {
			height: 75vh;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
`;

const Introduction = styled.div`
	padding-top: 30px;

	@media (min-width: ${bp.sm}) {
		min-height: 100vh;
	}
`;

const Title = styled.div`
	font-size: 50px;
	line-height: 1;
	text-align: center;
	filter: drop-shadow(2px 4px 9px black);
	padding: 0 24px;

	@media (min-width: ${bp.sm}) {
		font-size: 75px;
	}

	@media (min-width: ${bp.lg}) {
		font-size: 100px;
	}
`;
