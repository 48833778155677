import styled from "styled-components";
import { bp } from "./Breakpoints";

const AppWrapper = styled.div`
	* {
		scroll-behavior: smooth;
		scroll-margin-top: 60px;
	}
	@media (min-width: ${bp.sm}) {
		* {
			scroll-margin-top: 60px;
		}
	}
	font-family: "MW Regular", Arial, sans-serif;
	scroll-behavior: smooth;
	width: 100%;
	min-height: 100vh;
	background: #030304;
	color: white;
	margin: auto;
	padding: 48px 12px;
	display: flex;
	align-items: center;
	justify-content: center;
	letter-spacing: -0.01em;
	a {
		color: white;
		&:hover {
			text-decoration: none;
		}
	}

	video {
		width: 100%;
		height: auto;
		max-width: 800px;
		max-height: 50vh;
		margin: auto;
		display: flex;
		margin-bottom: 24px;
	}
`;

const InnerWrapper = styled.div`
	@media (min-width: ${bp.sm}) {
		width: 80vw;
	}

	@media (min-width: ${bp.lg}) {
		width: 66.66vw;
	}

	@media (min-width: ${bp.xl}) {
		width: 50vw;
		min-width: 650px;
		/* max-width: 800px; */
	}
`;

const Wrapper = styled.div`
	font-family: "MW Regular";
	font-size: 30px;
	line-height: 1.2;
	margin-bottom: 75px;
	text-align: justify;
	margin-left: auto;
	margin-right: auto;

	em {
		font-style: normal;
		color: #52ff00;
	}

	a {
		color: white;
		&:hover {
			text-decoration: none;
		}
	}

	mark {
		color: #52ff00;
		background: none;
	}

	p {
		font-size: 25px;
	}

	button {
		display: block;
		background: none;
		color: white;
		text-align: left;
		width: 100%;
		padding: 12px 0;
		border: none;
		cursor: pointer;
		border-top: 1px solid rgba(255, 255, 255, 0.25);

		&:hover {
			text-decoration: underline;
		}

		@media (hover: none) {
			a {
				&:hover {
					text-decoration: none;
				}
			}
		}
	}

	#first {
		border-top: 0px solid;
		padding-top: 0;
	}

	table {
		font-size: 25px;
	}

	table,
	th,
	td {
		border: 1px solid white;
	}

	td {
		width: 33%;
	}

	@media (min-width: ${bp.sm}) {
		font-size: 40px;
		width: 80vw;
		margin-bottom: 150;
	}

	@media (min-width: ${bp.lg}) {
		width: 66.66vw;
	}

	@media (min-width: ${bp.xl}) {
		width: 50vw;
		min-width: 650px;
		max-width: 1000px;
	}
`;

//FAQ Panel
const Panel = styled.div`
	padding: 0 12px;
	display: none;
	overflow: hidden;
	font-size: 25px;
	padding: 24px 0 0;

	img {
		width: 100%;
		height: auto;
	}

	td:first-child {
		padding-left: 0.96667rem;
	}

	th {
		font-weight: 400;
		color: #52ff00;

		@media (min-width: ${bp.sm}) {
			text-transform: uppercase;
		}
	}

	table {
		max-width: calc(100vw - 24px);
	}
`;

const SectionTitle = styled.div`
	font-family: "Fondamento";
	text-align: center;
	margin-bottom: 48px;
	color: white;
	position: sticky;
	top: 65px;
	background: #030304;
	font-size: 30px;
	padding-bottom: 4px;

	@media (min-width: ${bp.sm}) {
		font-size: 60px;
		top: 55px;
	}
`;

const Body = styled.div`
	font-size: 25px;
	text-align: ${(props) => (props.center ? "center" : "justified")};
	@media (min-width: ${bp.sm}) {
		font-size: 40px;
	}

	br {
		display: block; /* makes it have a width */
		content: ""; /* clears default height */
		margin-top: 25px; /* change this to whatever height you want it */
	}
`;

// FAQ Question style
const Question = styled.button`
	display: block;
	background: none;
	color: white;
	text-align: left;
	width: 100%;
	padding: 12px 0;
	border: none;
	cursor: pointer;
	border-top: ${(props) => (props.first ? "initial" : "1px solid rgba(255, 255, 255, 0.25)")};

	&:hover {
		text-decoration: underline;
	}

	@media (hover: none) {
		a {
			&:hover {
				text-decoration: none;
			}
		}
	}
`;

const BorderImage = styled.img`
	max-width: 100%;
	height: auto;
	border: 5px solid #52ff00;

	@media (min-width: ${bp.sm}) {
		display: none;
	}
`;

export { AppWrapper, InnerWrapper, Wrapper, SectionTitle, Panel, Body, Question, BorderImage };
