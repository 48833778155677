import styled from "styled-components";
import { bp } from "./Breakpoints";
import { Wrapper } from "./Styles";

function Intro() {
	return (
		<Wrapper>
			<Logo>
				<img
					alt="loot larp logo"
					src="https://res.cloudinary.com/wy/image/upload/v1637895272/loot-larp/LootLARP_logo.svg"
				></img>
			</Logo>
			is creating <em>digi-physical NFT gear</em> to be used at the first ever{" "}
			<em>Live Action Role-Playing Game event</em> based on the <em>Loot</em> ecosystem, set at <em>NFT.NYC in 2022</em>
			.
			<br />
			<br />
			<Join>
				Join{" "}
				<a href="https://discord.gg/dxFhYe7RYg" target="_blank" rel="noreferrer">
					our LootLARP discord channel
				</a>{" "}
				to gain access to our upcoming mint ⚔️
			</Join>
		</Wrapper>
	);
}

export default Intro;

const Logo = styled.span`
	img {
		margin: 0;
		padding: 0;
		margin-right: 24px;
		width: 120px;
		height: auto;
		transform: translateY(6px);
	}

	@media (min-width: ${bp.sm}) {
		img {
			margin: 0;
			padding: 0;
			margin-right: 24px;
			width: 165px;
			height: auto;
			transform: translateY(12px);
		}
	}
`;

const Join = styled.div`
	text-align: left;
`;
