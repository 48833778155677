import React, { Component } from "react";
import { Wrapper, Body, SectionTitle, BorderImage } from "./Styles";

export default class WTF extends Component {
	render() {
		return (
			<Wrapper>
				<BorderImage src="https://res.cloudinary.com/wy/image/upload/w_1800,f_auto,dpr_auto/v1638993009/loot-larp/Copie_de__DSC6035.jpg" />
				<SectionTitle id="wtf">WTF LARP?</SectionTitle>
				<Body>
					We are forging a series of physical adventurers' items from the{" "}
					<a href="https://www.lootproject.com/" target="_blank" rel="noreferrer">
						Loot
					</a>{" "}
					canon: <mark>katanas</mark>, <mark>maces</mark>, <mark>wands</mark>, <mark>divine robes</mark>, and beyond.{" "}
					<br />
					<br /> Once these items are <em>activated</em> with a <em>Loot</em> NFT via SiLo chips, new interactions
					unlock in a variety of smart contract games that play out simultaneously IRL and on the blockchain. <br />
					<br /> Our limited edition items can be purchased by minting a Redemption NFT and serve as your ticket to an
					IRL LARP experience at NFT.NYC.
					<br />
					<br />
				</Body>
			</Wrapper>
		);
	}
}
