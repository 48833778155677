import styled from "styled-components";
import { bp } from "./Breakpoints";
import { Wrapper, Body, SectionTitle, BorderImage } from "./Styles";

function Participate() {
	return (
		<Wrapper id="participate">
			<BorderImage src="https://res.cloudinary.com/wy/image/upload/w_1800,f_auto,dpr_auto/v1638993015/loot-larp/Copie_de_IMG_3957.jpg" />
			<SectionTitle>How to Participate</SectionTitle>
			<br />
			<Body center>
				<br />
				Join{" "}
				<a href="https://discord.gg/dxFhYe7RYg" target="_blank" rel="noreferrer">
					our LootLARP discord channel
				</a>{" "}
				to gain access to our upcoming mint ⚔️
				<br />
				<br />
				Follow us on Twitter{" "}
				<a href="https://www.twitter.com/LootLARP" target="_blank" rel="noreferrer">
					@LootLARP
				</a>
			</Body>
			<br />
			<Symbol src="https://res.cloudinary.com/wy/image/upload/v1638744352/loot-larp/LootLARP_Logo_Symbol_White.svg" />
		</Wrapper>
	);
}

export default Participate;

const Symbol = styled.img`
	width: 75px;
	height: auto;
	display: flex;
	margin: auto;
	padding-bottom: 48px;
	margin-top: 48px;

	@media (min-width: ${bp.sm}) {
		width: 100px;
	}
`;
