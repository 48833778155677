import React, { Component } from "react";
import styled from "styled-components";
import { AppWrapper, InnerWrapper, Wrapper, Body, SectionTitle } from "./components/Styles";
import Navigation from "./components/Navigation";
import { HashLink as Link } from "react-router-hash-link";

export default class ItemForge extends Component {
	render() {
		return (
			<AppWrapper id="item-forge">
				<Navigation />
				<Wrapper>
					<InnerWrapper>
						<Title>
							<Center>
								Welcome to the Item Forge 🔥🔨 <br />
							</Center>
						</Title>
						<Body>
							Only one weapon can be crafted from the paired Loot NFT options per Redemption NFT, and{" "}
							<mark>this choice is irreversible</mark>— choose wisely!
							<br />
							<br />
							Choose to pair a Redemption NFT with a Loot or More Loot NFT below:
						</Body>
						<br />
						<Center>
							<Body>
								<Link to="/">Back</Link>
							</Body>
						</Center>
					</InnerWrapper>
				</Wrapper>
			</AppWrapper>
		);
	}
}

const Center = styled.div`
	text-align: center;
`;

const Title = styled(SectionTitle)`
	position: initial;
`;
